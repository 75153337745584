import React from "react"
import { Layout } from "components/layouts/Layout/layout"
import BrandList from "../components/elements/Brands"

export default function Brands() {
  return (
    <Layout>
      <>
        <div className="m-8">
          <h1 className="text-5xl font-bold text-idc-title">Brands</h1>
          <p className="mt-8 text-lg font-medium text-idc-body">
            <BrandList />
          </p>
        </div>
      </>
    </Layout>
  )
}
