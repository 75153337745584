//list all shopify brands in the store, then check if a collection exists by the same title, if there does, link to the collection handle and present beautifully in two columns using tailwindcss
import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

const BrandList = () => (
  <StaticQuery
    query={graphql`
      {
        allShopifyProduct {
          nodes {
            vendor
          }
          totalCount
          pageInfo {
            perPage
          }
          distinct(field: vendor)
        }
        allShopifyCollection {
          nodes {
            title
            handle
          }
        }
      }
    `}
    render={(data) => (
      <div className="flex flex-wrap mb-5 px-3 md:px-0">
        {data.allShopifyProduct.distinct.map(function (brand) {
          const collection = data.allShopifyCollection.nodes.find(function (
            collection
          ) {
            return collection.title === brand
          })

          if (collection) {
            return (
              <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-3 mb-5">
                <Link
                  key={collection.title}
                  to={`/collections/${collection.handle}`}
                >
                  <div className="relative flex items-center vendor text-grey hover:text-black text-sm font-semibold uppercase mr--6 noUnderline">
                    {brand}
                  </div>
                </Link>
              </div>
            )
          }
        })}
      </div>
    )}
  />
)

export default BrandList
